import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { Season } from '@services'
import { Subscription } from 'rxjs'
import { SeFeMenuComponent, SeFeMenuOptions, SeFeMenuItem, SeFeMenuSection } from 'se-fe-menu'
import { SeFeButtonEmphasis } from 'se-fe-button'
import { cloneDeep } from 'lodash'

export interface ListFitlerOption {
  label: string
  value: string | number
}
export interface ListFitlerOptGroup {
  label: string
  value: ListFitlerOption[]
}
export interface ListFilter {
  label?: string
  key: string
  menu: SeFeMenuOptions
  placeholder?: string
  options: Array<ListFitlerOption | ListFitlerOptGroup>
  type: 'quick' | 'select'
}

export interface ActiveFilters {
  [key: string]: string | number
}

export enum DateFilter {
  Season = 'season',
  Past = 'past',
  Today = 'today',
  ThisWeek = 'this_week',
  NextWeek = 'next_week',
  NextMonth = 'next_month',
}

export enum EventFilter {
  All = '',
  Game = 'games',
  Event = 'event',
}

// TODO: these should come from a translation layer at some point
const DATE_LABELS = {
  season: 'Season Start',
  past: 'In the Past',
  today: 'Today',
  this_week: 'This Week',
  next_week: 'Next Week',
  next_month: 'Next Month',
}

// Filter Dimensions
const MAX_HEIGHT = 'var(--filter-max-height)'
const MIN_WIDTH = '140px'

@Component({
  selector: 'sm-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss']
})
export class ListFiltersComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() public date = false
  @Input() public divisions: any[]
  @Input() public hideDivisionFilter = false
  @Input() public eventType = false
  @Input() public locations: any[]
  @Input() public positions: any[]
  @Input() public route: ActivatedRoute
  @Input() public teams: any[]

  @Output() public filterChange = new EventEmitter<ActiveFilters>()

  @HostBinding('class.sm-list-filters') public baseClass = true
  @HostListener('window:scroll') private onScroll = debounce(() => {
    this.updateCssRef()
    this.updateScrollStyle()
  })

  @ViewChild('wrapper') wrapper: ElementRef
  @ViewChild('filtersMenu') filtersMenu: SeFeMenuComponent
  @ViewChildren(SeFeMenuComponent) allMenus: QueryList<SeFeMenuComponent>

  public highEmphasis = SeFeButtonEmphasis.HIGH
  public filtersMenuOptions = { name: 'filtersMenu', maxHeight: MAX_HEIGHT }
  public filtersForm: FormGroup
  public filtersFormSubscription: Subscription
  public resetDisabled = true
  public canScroll = false
  public season: Season
  public dateMenu: SeFeMenuOptions
  public labels: any = {}
  public filters: ListFilter[] = []
  public menuFilters: ListFilter[] = []
  public activeFilters: ActiveFilters = {
    date: '',
    division: '',
    location: '',
    team: '',
    type: '',
  }

  private teamOptionByDivision: { [key: string]: ListFitlerOption[] } = {} // sorted options for a specific division
  private teamOptGroups: Array<ListFitlerOption | ListFitlerOptGroup> = [] // sorted options for all divisions
  private teamMenuItemsByDivision: { [key: string]: SeFeMenuItem[] } = {} // sorted items for a specific division
  private teamMenuSections: SeFeMenuSection[] = [] // sorted sections for all divisions
  private initialized = false
  private subscriptions = new Subscription()

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.season = Season.current
  }

  public ngOnInit(): void {
    this.season = Season.current

    if (this.date) this.setupDateFilters()
    if (this.divisions && !this.hideDivisionFilter) this.setupDivisionFilters()
    if (this.teams) this.setupTeamFilters()
    if (this.locations) this.setupLocationFilters()
    if (this.eventType) this.setupEventFilters()
    // if (this.positions) this.setupPositionFilters() // TODO: implement when expanding to other view

    this.menuFilters = this.filters.filter(f => f.type !== 'quick')
    this.filtersForm = this.fb.group(this.filtersFormValues())
    const divisionControl = this.filtersForm.controls.division
    if (divisionControl) this.subscriptions.add(divisionControl.valueChanges.subscribe(this.updateTeamOptions))
    this.subscriptions.add(this.route.params.subscribe(this.paramsToFilters))
    setTimeout(() => this.updateCssRef()) // allow render to take place
  }

  public ngAfterViewInit(): void {
    this.updateScrollStyle() // set initial scroll style
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  public updateScrollStyle(): void {
    const el = this.wrapper.nativeElement
    this.canScroll = Math.ceil(el.scrollLeft) + el.clientWidth < el.scrollWidth
  }

  public textFor(filter: ListFilter): string {
    return (filter.key === 'date') ? filter.label : this.fitlerLabel(filter)
  }

  public labelFor(filter: ListFilter): string {
    return (filter.key === 'date') ? this.fitlerLabel(filter) : ''
  }

  public clearFilters(): void {
    this.updateUrl({})
    this.filtersMenu?.closeMenu()
    this.updateScrollStyle()
  }

  public setFilter(prop: string, value: string | number, updateUrl = true): void {
    const filters = { ...this.route.snapshot.params, ...this.activeFilters }
    filters[prop] = value
    if (prop === 'division' && this.teamInOtherDivision(filters.team, value as string)) filters.team = ''
    if (updateUrl) this.updateUrl(filters)
    else this.paramsToFilters(filters) // bypass route change for ajsuted filters (date) on intial load
    this.updateScrollStyle()
  }

  private filtersFormValues(): any {
    const values = {}
    this.menuFilters.forEach(f => values[f.key] = this.activeFilters[f.key] || '')
    return values
  }

  public resetFiltersForm(): void {
    this.filtersForm.reset(this.filtersFormValues())
    this.filtersMenu?.closeMenu()
  }

  public applyFilters(): void {
    const filters = { ...this.route.snapshot.params, ...this.activeFilters, ...this.filtersForm.value }
    this.updateUrl(filters)
    this.filtersMenu.closeMenu()
  }

  private updateCssRef(): void {
    const el = this.elementRef.nativeElement
    const rect = el.getBoundingClientRect()
    el.style.setProperty('--filter-max-height', `calc(100vh - ${rect.bottom}px)`)
  }

  private fitlerLabel(filter: ListFilter): string {
    const value = this.activeFilters[filter.key] || ''
    return this.labels[filter.key][value]
  }

  private addFilter(label: string, key: string, type: ListFilter['type'], items: any[], placeholder?: string): void {
    const itemsWithPlaceholder = placeholder ? [{ name: placeholder, id: '' }, ...items] : items
    const menuItems = this.menuItems(key, itemsWithPlaceholder)
    const options = this.filterOptions(items)
    const menu = {
      name: `${key}FilterMenu`,
      maxHeight: MAX_HEIGHT,
      minWidth: MIN_WIDTH,
      sections: [{ menuItems }],
    }
    const labels = this.labels[key] = this.labels[key] || {}
    itemsWithPlaceholder.forEach((item) => labels[item.id] = item.name)
    this.filters.push({ type, label, placeholder, options, key, menu })
  }

  private menuItems(key, items: any[]): SeFeMenuItem[] {
    return items.map(item => ({ text: item.name, action: () => this.setFilter(key, item.id)}))
  }

  private filterOptions(items: any[]): ListFitlerOption[] {
    return items.map(item => ({ label: item.name, value: item.id}))
  }

  private setupDateFilters(): void {
    const items = Object.values(DateFilter).map((opt) => ({ name: DATE_LABELS[opt], id: opt }))
    this.addFilter('Jump To', 'date', 'quick', items)
    this.labels.date[''] = DATE_LABELS.today
  }

  private setupDivisionFilters(): void {
    this.addFilter('Division', 'division', 'select', this.divisions, 'All Divisions')
  }

  private setupTeamFilters(): void {
    const teamsCopy = cloneDeep(this.teams)
    teamsCopy.map(t => t.id = t.team_service_id) // use team_service_id for events endpoint filter
    const placeholder = 'All Teams'
    const placeholderItem = this.menuItems('team', [{ name: placeholder, id: '' }])
    const teams = sortBy(teamsCopy, 'name')
    const divTeams = groupBy(teams, 'flight_id')
    const divisions = sortBy(this.divisions, 'name')

    this.teamMenuSections.push({ menuItems: placeholderItem })
    divisions.forEach(d => {
      const menuItems = this.menuItems('team', divTeams[d.id] || [])
      const value = this.teamOptionByDivision[d.id] = this.filterOptions(divTeams[d.id] || [])
      this.teamMenuItemsByDivision[d.id] = [...placeholderItem, ...menuItems]
      this.teamMenuSections.push({ header: d.name, menuItems })
      this.teamOptGroups.push({ label: d.name, value })
    })

    this.addFilter('Team', 'team', 'select', teams, placeholder)
  }

  private updateTeamOptions = (division): void => {
    if (!this.teams) return
    const filter = this.filters.find(f => f.key === 'team')

    if (!division) {
      filter.options = this.teamOptGroups
      return
    }

    filter.options = this.teamOptionByDivision[division]
    if (this.teamInOtherDivision(this.filtersForm.value.team, division)) this.filtersForm.controls.team.setValue('')
  }

  private teamInOtherDivision(teamId: string, divisionId: string): boolean {
    if (!teamId || !divisionId) return false
    return this.teams.find(t => t.id === teamId).flight_id !== divisionId
  }

  private updateTeamMenuItems(): void {
    if (!this.teams) return
    const teamFilter = this.filters.find(f => f.key === 'team')
    const divId = this.activeFilters.division as string
    teamFilter.menu.sections = divId ? [{ menuItems: this.teamMenuItemsByDivision[divId] }] : this.teamMenuSections
  }

  private setupEventFilters(): void {
    const types = [
      { name: 'Games', id: 'game' },
      { name: 'Events', id: 'event' },
    ]
    this.addFilter('Event Type', 'type', 'select', types, 'Games and Events')
  }

  private setupLocationFilters(): void {
    this.addFilter('Location', 'location', 'select', this.locations, 'All Locations')
  }

  // private setupPositionFilters(): void {
  //   // TODO: figure out how position filters look
  // }

  private updateUrl(filters: ActiveFilters): void {
    this.router.navigate([this.removeEmpty(filters)], { relativeTo: this.route, replaceUrl: true, queryParamsHandling: 'merge' })
  }

  private paramsToFilters = (params): void => {
    if (this.updateFilters(params)) this.filterChange.emit(this.removeEmpty(this.activeFilters))
  }

  private updateFilters(params): boolean {
    const prevFilters = JSON.stringify(this.activeFilters)
    const prevDivisionFilter = this.activeFilters.division
    this.resetDisabled = true
    for (const key of Object.keys(this.activeFilters)) {
      this.activeFilters[key] = params[key]
      if (params[key] && this.resetDisabled && (key !== 'division' || !this.hideDivisionFilter)) this.resetDisabled = false
    }
    const changed = !this.initialized || JSON.stringify(this.activeFilters) !== prevFilters
    const divisionChanged = !this.initialized || this.activeFilters.division !== prevDivisionFilter
    if (divisionChanged) this.updateTeamMenuItems()
    this.initialized = true
    return changed
  }

  private removeEmpty(filters): any {
    const res = {}
    for (const key of Object.keys(filters)) {
      const val = filters[key]
      if (val || val === 0) res[key] = val
    }
    return res
  }

}
